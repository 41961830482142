<template>
  <h1>
    404
    {{ $t('Page not found') }}
  </h1>
</template>

<script>
export default {
  name: 'PageNotFound',
}
</script>

<style scoped>
h1 {
  font-size: clamp(1.5rem, 3vw, 4rem);
  text-align: center;
}
</style>
